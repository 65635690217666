import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    em: "em",
    h2: "h2",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsxs(_components.em, {
        children: ["See ", _jsx(_components.a, {
          href: "/docs/appendix-state-management",
          children: "state management"
        }), " for more explanation."]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["We are using ", _jsx(_components.a, {
        href: "https://github.com/pmndrs/valtio",
        children: "valtio"
      }), " as a global state management library."]
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "app-state.mjs"
      }), " module is a thin wrapper around valtio, that does a few things:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Segregate read and write into two objects. By default, valtio returns one mutable state. This is to implement automatic persistence with the ", _jsx(_components.code, {
          children: "writeState"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["Automatically persist state using ", _jsx(_components.code, {
          children: "writeState"
        }), ", and enforce a few rules around its usage."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "least-recently-used-lru",
      children: _jsx(_components.a, {
        href: "#least-recently-used-lru",
        children: "Least Recently Used (LRU)"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Like an ", _jsx(_components.a, {
        href: "https://www.quora.com/How-do-open-world-games-go-without-interstitial-loading-screens",
        children: "open world game without loading screens between areas"
      }), ", this is possible by only loading what is ", _jsx(_components.em, {
        children: "nearby"
      }), " to the user and freeing memory of distant locations. In this case, nearby data would be for the current route, and the previous route, and a few routes before that."]
    }), "\n", _jsx(_components.p, {
      children: "We are storing state with arbitrary keys, and automatically cleaning up stale paths by least recently used. For example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  profiles: {\n    0: {...},\n    1: {...},\n    2: {...},\n    ...\n    n: {...},\n  }\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["As more paths get written to the state, the oldest ones are cleaned up first (0, 1, 2...). ", _jsx(_components.strong, {
        children: "The number of paths allowed is limited to a finite number, ensuring stable memory usage."
      })]
    }), "\n", _jsx(_components.p, {
      children: "A more trivial solution to memory management would be to allow only one resource per type active at a time:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  profile: {...},\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "This guarantees that memory won't leak, but comes with drawbacks:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Can only keep one profile in memory, so navigating back and forth between different profiles needs DB/network request."
      }), "\n", _jsx(_components.li, {
        children: "Makes some views impossible, particularly if more than one profile's data needs to be shown."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "writing-paths",
      children: _jsx(_components.a, {
        href: "#writing-paths",
        children: "Writing paths"
      })
    }), "\n", _jsx(_components.p, {
      children: "We want to guarantee that the paths we write to are conflict-free. Consider this data structure:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  a: {\n    b: {\n      c: 1,\n      d: 2,\n      [isPersistent]: true,\n    },\n  },\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "In this example, the object \"b\" is the only object that is persisted. What could go wrong if we write to an invalid path?"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "If object \"a\" is written, then we have duplicated the data that already exists in object \"b\"."
      }), "\n", _jsx(_components.li, {
        children: "If key \"c\" or \"d\" is written to directly, then we have mutated data from object \"b\" without persisting."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Therefore, the only valid path to write to is \"a.b\"."
    }), "\n", _jsx(_components.h2, {
      id: "using-state-in-components",
      children: _jsx(_components.a, {
        href: "#using-state-in-components",
        children: "Using state in components"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The following is an example that shows the stale-while-revalidate loading state. It's optional to account for ", _jsx(_components.code, {
        children: "isLoaded"
      }), " but can be helpful to show which parts of the UI are revalidating."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-jsx",
        children: "function MyComponent() {\n  const { thing } = useSnapshot(readState);\n  const isLoaded = useIsLoaded(); // this one is optional!\n\n  if (!thing) return <Loading />;\n\n  return (\n    <>\n      {!isLoaded ? <Loading /> : null}\n      <Thing thing={thing} />\n    </>\n  );\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Note that ", _jsx(_components.code, {
        children: "useIsLoaded"
      }), " shouldn't be used exclusively to gate content. This has the negative side effect of not showing any cached data while revalidating."]
    }), "\n", _jsx(_components.h2, {
      id: "initial-state",
      children: _jsx(_components.a, {
        href: "#initial-state",
        children: "Initial state"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["There is a non-empty initial state which represents the overall structure, including a few defaults for settings. Initial state has a ", _jsx(_components.code, {
        children: "isInitial"
      }), " symbol which informs whether it should try to read a path from DB or not."]
    }), "\n", _jsxs(_components.p, {
      children: ["Feature modules may need to initialize state, and the preferred way to do this is using ", _jsx(_components.code, {
        children: "addInitialPath"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "import addInitialPath from \"@/util/add-initial-path.mjs\";\n\nexport function setup() {\n  addInitialPath([\"featurePath\"], {\n    featureKeyA: { ... },\n    featureKeyB: null,\n    featureKeyC: 0,\n  });\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "This way, initial state can be added even after the state has been initialized."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
